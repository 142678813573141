import { createSlice } from "@reduxjs/toolkit";
import { apiCallBegan } from "../api";

const slice = createSlice({
  name: "employee_expenses",
  initialState: {
    employee_expenses: [],
    employee_expenses_count: 0,
    employee_expenses_loading: false,
    employee_expense_saving: false,
    employee_expense_saved: false,
    employee_expense_deleting: false,
    team_expenses: [],
    team_expenses_count: 0,
    team_expenses_loading: false,
  },

  reducers: {
    employeeExpensesRequested: (state, action) => {
      state.employee_expenses_loading = true;
    },

    employeeExpensesReceived: (state, action) => {
      const { employee_expenses, total_count } = action.payload;
      state.employee_expenses = employee_expenses;
      state.employee_expenses_count = total_count;
      state.employee_expenses_loading = false;
    },

    employeeExpensesRequestFailed: (state, action) => {
      state.employee_expenses_loading = false;
    },

    employeeExpenseCreationRequested: (state, action) => {
      state.employee_expense_saving = true;
      state.employee_expense_saved = false;
    },

    employeeExpenseCreationReceived: (state, action) => {
      const { employee_expense } = action.payload;
      if (employee_expense) {
        state.employee_expenses.push(employee_expense);
      }
      state.employee_expense_saving = false;
      state.employee_expense_saved = true;
    },

    employeeExpenseCreationFailed: (state, action) => {
      state.employee_expense_saving = false;
    },

    employeeExpenseUpdationRequested: (state, action) => {
      state.employee_expense_saving = true;
      state.employee_expense_saved = false;
    },

    employeeExpenseUpdationReceived: (state, action) => {
      const { employee_expense } = action.payload;

      const expenseIndex = state.employee_expenses.findIndex(
        (item) => item.id === employee_expense.id
      );
      if (expenseIndex !== -1) {
        state.employee_expenses[expenseIndex] = employee_expense;
      }
      state.employee_expense_saving = false;
      state.employee_expense_saved = true;
    },

    employeeExpenseUpdationFailed: (state, action) => {
      state.employee_expense_saving = false;
    },

    employeeExpenseDeletionRequested: (state, action) => {
      state.employee_expense_deleting = true;
    },

    employeeExpenseDeletionReceived: (state, action) => {
      const { id } = action.payload;
      const expenseIndex = state.employee_expenses.findIndex(
        (item) => item.id === id
      );
      state.employee_expenses.splice(expenseIndex, 1);
      state.employee_expense_deleting = false;
    },

    employeeExpenseDeletionFailed: (state, action) => {
      state.employee_expense_deleting = false;
    },

    teamExpensesRequested: (state, action) => {
      state.team_expenses_loading = true;
    },

    teamExpensesReceived: (state, action) => {
      const { expenses, total_count } = action.payload;
      state.team_expenses = expenses;
      state.team_expenses_count = total_count;
      state.team_expenses_loading = false;
    },

    teamExpensesRequestFailed: (state, action) => {
      state.team_expenses_loading = false;
    },

    actionOnEmployeeExpenseRequested: (state, action) => {
      state.employee_expense_saving = true;
      state.employee_expense_saved = false;
    },

    actionOnEmployeeExpenseReceived: (state, action) => {
      const { id, expense } = action.payload;
      const expenseIndex = state.team_expenses.findIndex(
        (item) => item.uid === id
      );
      if (action.requestType === "update_approved_amount") {
        state.team_expenses[expenseIndex] = expense;
      } else {
        state.team_expenses.splice(expenseIndex, 1);
      }
      state.employee_expense_saving = false;
      state.employee_expense_saved = true;
    },

    actionOnEmployeeExpenseFailed: (state, action) => {
      state.employee_expense_saving = false;
    },

    resetActionStates: (state, action) => {
      state.employee_expenses_loading = false;
      state.employee_expense_saving = false;
      state.employee_expense_saved = false;
      state.employee_expense_deleting = false;
      state.employee_expense_document_deletion_loading = false;
      state.employee_expense_document_deleted = false;
    },
  },
});

export default slice.reducer;

const {
  employeeExpensesRequested,
  employeeExpensesReceived,
  employeeExpensesRequestFailed,
  employeeExpenseCreationRequested,
  employeeExpenseCreationReceived,
  employeeExpenseCreationFailed,
  employeeExpenseUpdationRequested,
  employeeExpenseUpdationReceived,
  employeeExpenseUpdationFailed,
  employeeExpenseDeletionRequested,
  employeeExpenseDeletionReceived,
  employeeExpenseDeletionFailed,
  teamExpensesRequested,
  teamExpensesReceived,
  teamExpensesRequestFailed,
  actionOnEmployeeExpenseRequested,
  actionOnEmployeeExpenseReceived,
  actionOnEmployeeExpenseFailed,
  resetActionStates,
} = slice.actions;

export const getEmployeeExpenses = (payload) => (dispatch) => {
  let endpoint = `v1/hrms/employees/${payload.employee_id}/expenses`;

  const queryParams = new URLSearchParams();

  if (payload.filters?.statuses?.length > 0) {
    queryParams.append("statuses", payload.filters.statuses.join());
  }

  if (payload.filters?.expense_category_ids?.length > 0) {
    queryParams.append(
      "expense_category_ids",
      payload.filters.expense_category_ids.join()
    );
  }

  queryParams.append("page", payload.page);
  queryParams.append("per", payload.per);

  if (queryParams.toString()) {
    endpoint += `?${queryParams.toString()}`;
  }

  return dispatch(
    apiCallBegan({
      url: endpoint,
      onStart: employeeExpensesRequested.type,
      onSuccess: employeeExpensesReceived.type,
      onError: employeeExpensesRequestFailed.type,
    })
  );
};

export const createEmployeeExpense = (payload) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `v1/hrms/employees/${payload.get("employee_id")}/expenses`,
      method: "POST",
      data: payload,
      type: "creation",
      onStart: employeeExpenseCreationRequested.type,
      onSuccess: employeeExpenseCreationReceived.type,
      onError: employeeExpenseCreationFailed.type,
      extraHeaders: { "Content-Type": "multipart/form-data" },
      showAlert: true,
      successMessage: "Expense created successfully!",
    })
  );
};

export const updateEmployeeExpense = (payload) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `v1/hrms/employees/${payload.get(
        "employee_id"
      )}/expenses/${payload.get("expense_id")}`,
      method: "PATCH",
      data: payload,
      type: "updation",
      onStart: employeeExpenseUpdationRequested.type,
      onSuccess: employeeExpenseUpdationReceived.type,
      onError: employeeExpenseUpdationFailed.type,
      extraHeaders: { "Content-Type": "multipart/form-data" },
      showAlert: true,
      successMessage: "Expense updated successfully!",
    })
  );
};

export const deleteEmployeeExpense = (payload) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `v1/hrms/employees/${payload.employee_id}/expenses/${payload.expense_id}`,
      method: "DELETE",
      type: "deletion",
      onStart: employeeExpenseDeletionRequested.type,
      onSuccess: employeeExpenseDeletionReceived.type,
      onError: employeeExpenseDeletionFailed.type,
      showAlert: true,
      successMessage: "Expense deleted successfully!",
    })
  );
};

export const getTeamExpenses = (payload) => (dispatch) => {
  let endpoint = `v1/hrms/${payload.expenses_of}/employees_expenses`;

  const queryParams = new URLSearchParams();

  if (payload.status) {
    queryParams.append("status", payload.status);
  }

  if (payload.filters?.employee_ids?.length > 0) {
    queryParams.append("employee_ids", payload.filters.employee_ids.join());
  }

  if (payload.filters?.expense_category_ids?.length > 0) {
    queryParams.append(
      "expense_category_ids",
      payload.filters.expense_category_ids.join()
    );
  }

  queryParams.append("page", payload.page);
  queryParams.append("per", payload.per);

  if (queryParams.toString()) {
    endpoint += `?${queryParams.toString()}`;
  }

  return dispatch(
    apiCallBegan({
      url: endpoint,
      onStart: teamExpensesRequested.type,
      onSuccess: teamExpensesReceived.type,
      onError: teamExpensesRequestFailed.type,
    })
  );
};

export const takeActionOnEmployeeExpense = (payload) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `v1/hrms/${payload.expenses_of}/employees_expenses/${payload.expense_id}/${payload.action}`,
      method: "PATCH",
      type: payload.action,
      data: payload.body,
      onStart: actionOnEmployeeExpenseRequested.type,
      onSuccess: actionOnEmployeeExpenseReceived.type,
      onError: actionOnEmployeeExpenseFailed.type,
      showAlert: true,
    })
  );
};

export const resetActions = () => (dispatch) => {
  return dispatch(resetActionStates());
};
