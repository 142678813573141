const LightTheme = {
  palette: {
    neutral: {
      main: "#64748B",
      contrastText: "#fff",
    },
    bold: {
      main: "#64748B",
      contrastText: "#fff",
    },
    branding: {
      main: "#4B0082",
      contrastText: "#FFF",
    },
    primary: {
      main: "#5060ff",
      contrastText: "#FFF",
    },
    default: {
      main: "#4e4e4e",
      contrastText: "#FFF",
      name: "default",
    },
    lightBlack: {
      main: "#636363",
      contrastText: "#FFF",
      name: "lightBlack",
    },
    black: {
      main: "#000000",
      contrastText: "#FFF",
      name: "black",
    },
    blue: {
      main: "#1F51FF",
      contrastText: "#FFF",
      name: "blue",
    },
    mediumBlue: {
      main: "#787df4",
      contrastText: "#FFF",
      name: "mediumBlue",
    },
    lightBlue: {
      main: "#f1f2ff",
      contrastText: "#1F51FF",
      name: "lightBlue",
    },
    brandingBlue: {
      main: "#032ef7",
      contrastText: "#FFF",
      name: "brandingBlue",
    },
    white: {
      main: "#FFFFFF",
      contrastText: "#000",
      name: "white",
    },
    red: {
      main: "#ff0033",
      contrastText: "#FFF",
      name: "red",
    },
    orange: {
      main: "#ff6700",
      contrastText: "#FFF",
      name: "orange",
    },
    green: {
      main: "#32CD32",
      contrastText: "#FFF",
      name: "green",
    },
    lightGreen: {
      main: "#f0faf2",
      contrastText: "#008000",
      name: "lightGreen",
    },
    mediumGreen: {
      main: "#d6f1e2",
      contrastText: "#008000",
      name: "mediumGreen",
    },
    darkGreen: {
      main: "#008000",
      contrastText: "#FFF",
      name: "darkGreen",
    },
    purple: {
      main: "#4B0082",
      contrastText: "#FFF",
      name: "purple",
    },
    lightGray: {
      main: "#0000001F",
      contrastText: "#000",
      name: "lightGray",
    },
    mediumGray: {
      main: "#716e6e",
      contrastText: "#FFF",
      name: "mediumGray",
    },
    borderGray: {
      main: "#ccc",
      contrastText: "#FFF",
      name: "borderGray",
    },
    ceruleanBlue: {
      main: "#0592ec",
      contrastText: "#FFF",
      name: "ceruleanBlue",
    },
    themeWhite: {
      main: "#f4f5ff",
      contrastText: "#000",
      name: "themeWhite",
    },
    typography: {
      main: "#000",
      header: "#000",
      title: "#000",
      titleMedium: "#597e8d",
      content: "#000",
      name: "typography",
      smallTitle: { fontSize: "0.9rem", variant: "h6" },
    },
    pink: {
      main: "#f110ee",
      contrastText: "#FFF",
      name: "pink",
    },
    lightPink: {
      main: "#fae1f8",
      contrastText: "#f110ee",
      name: "lightPink",
    },
    darkTheme: {
      main: "#07070f",
      contrastText: "#9dc3dd",
      name: "darkTheme",
    },
    periwinkle: {
      main: "#CCCCFF",
      contrastText: "#FFF",
      name: "periwinkle",
    },
    theme: {
      main: "#000",
    },
    tabBorder: {
      main: "#dce1f5",
    },
    mode: "light",
  },
  typography: {
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    subtitle1: { fontSize: "1rem", fontWeight: 500, lineHeight: 1.5 },
    subtitle2: { fontSize: "0.875rem", fontWeight: 500, lineHeight: 1.5 },
    body1: { fontSize: "0.938rem", fontWeight: 400, lineHeight: 1.4 },
    body2: { fontSize: "0.875rem", fontWeight: 400, lineHeight: 1.4 },
  },
  custom: {
    main: "#FFF",
    themeColorCode: "#FFF",
    themeColor: "white",
    themeContrastColor: "black",
    themeContrastColorCode: "#000",
    appPackedIconColor: "primary",
    appMainMenuActiveColor: "#1F51FF",
    appMainMenuColor: "#000",
    sideMenuItemHoverColor: "#afb2f8",
    tabBorderColor: "#dce1f5",
    chartStrokeColor: "#636363",
    cardInfoBackground: "#f2f4ff",
    calenderSliderSideButtonsColor: "black",
    thickTransparentThemeBackground: "rgba(255, 255, 255, 0.95) !important",
    boxShadow: "none",
    cardBackground: "#f2f8ff",
    appBarBackground: "rgba(255, 255, 255, 0.95) !important",
    mobileAppBarBackground: "rgba(255, 255, 255, 0.95) !important",
    tableBorderColor: "#ccc",
    cardLabelColorCode: "#606c7d",
    borderColor: "#c1c4d1",
    specialCardBackground: "#1f51ff0a",
  },
  components: {
    MuiTableCell: {
      styleOverrides: {
        root: {
          // borderBottom: "0.5px solid #c1c4d1",
          borderWidth: "0.75px",
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          // backgroundColor: "#FF1493",
          background: "#32CD32",
          color: "#FFFFFF",
          // fontWeight: 500,
          textTransform: "capitalize",
          opacity: 1,
          "& .MuiChip-deleteIcon": {
            color: "#FFFFFF",
            opacity: 0.7,
            "&:hover": {
              opacity: 1,
              color: "#FFFFFF",
            },
          },
        },
      },
      variants: [
        {
          props: { variant: "outlined" },
          style: {
            background: "#FFF",
            border: "1px solid #ccc",
            color: "#212121",
            "&:hover": {
              background: "#FFF",
            },
            "& .MuiChip-deleteIcon": {
              color: "#212121",
              opacity: 0.7,
              "&:hover": {
                opacity: 1,
                color: "#212121",
              },
            },
          },
        },
        {
          props: { variant: "border-less" },
          style: {
            background: "#F5F5F5",
            border: "none",
            color: "#212121",
            fontWeight: 400,
            "&:hover": {
              background: "#FAFAFA",
            },
          },
        },
        {
          props: { variant: "rejected" },
          style: {
            background: "#ff0033",
            border: "none",
            color: "#FFF",
            fontWeight: 400,
            "&:hover": {
              background: "#ff0033",
            },
          },
        },
        {
          props: { variant: "red" },
          style: {
            background: "#ff0033",
            border: "none",
            color: "#FFF",
            fontWeight: 400,
            "&:hover": {
              background: "#ff0033",
            },
          },
        },
        {
          props: { variant: "red-green" },
          style: {
            background: "linear-gradient(to right, red 50%, #32CD32 50%)",
            border: "none",
            color: "#FFF",
            fontWeight: 400,
            "&:hover": {
              background: "linear-gradient(to right, red 50%, #32CD32 50%)",
            },
          },
        },
        {
          props: { variant: "green-red" },
          style: {
            background: "linear-gradient(to right, #32CD32 50%, red 50%)",
            border: "none",
            color: "#FFF",
            fontWeight: 400,
            "&:hover": {
              background: "linear-gradient(to right, #32CD32 50%, red 50%)",
            },
          },
        },
        {
          props: { variant: "success" },
          style: {
            background: "#32CD32",
            border: "none",
            color: "#FFF",
            fontWeight: 400,
            "&:hover": {
              background: "#32CD32",
            },
          },
        },
        {
          props: { variant: "green" },
          style: {
            background: "#32CD32",
            border: "none",
            color: "#FFF",
            fontWeight: 400,
            "&:hover": {
              background: "#32CD32",
            },
          },
        },
        {
          props: { variant: "light-green" },
          style: {
            background: "#e8faeb",
            border: "none",
            color: "#32CD32",
            fontWeight: 400,
            "&:hover": {
              background: "#e8faeb",
            },
          },
        },
        {
          props: { variant: "pending" },
          style: {
            background: "#FFA500",
            border: "none",
            color: "#FFF",
            fontWeight: 400,
            "&:hover": {
              background: "#FFA500",
            },
          },
        },
        {
          props: { variant: "light-orange" },
          style: {
            background: "#fbf1e8",
            border: "none",
            color: "#FFA500",
            fontWeight: 400,
            "&:hover": {
              background: "#fbf1e8",
            },
          },
        },
        {
          props: { variant: "orange" },
          style: {
            background: "#FFA500",
            border: "none",
            color: "#FFF",
            fontWeight: 400,
            "&:hover": {
              background: "#FFA500",
            },
          },
        },
        {
          props: { variant: "light-pink" },
          style: {
            background: "#fae1f8",
            border: "none",
            color: "#f110ee",
            fontWeight: 400,
            "&:hover": {
              background: "#fae1f8",
            },
          },
        },
        {
          props: { variant: "pink" },
          style: {
            background: "#f110ee",
            border: "none",
            color: "#FFF",
            fontWeight: 400,
            "&:hover": {
              background: "#f110ee",
            },
          },
        },
        {
          props: { variant: "medium-green" },
          style: {
            background: "#01EEBB",
            border: "none",
            color: "#FFF",
            fontWeight: 400,
            "&:hover": {
              background: "#01EEBB",
            },
          },
        },
        {
          props: { variant: "dark-green" },
          style: {
            background: "#008000",
            border: "none",
            color: "#FFF",
            fontWeight: 400,
            "&:hover": {
              background: "#008000",
            },
          },
        },
        {
          props: { variant: "light-blue" },
          style: {
            background: "#eaeaf9",
            border: "none",
            color: "#1F51FF",
            fontWeight: 400,
            "&:hover": {
              background: "#eaeaf9",
            },
          },
        },
        {
          props: { variant: "light-red" },
          style: {
            background: "#ffedf0",
            border: "none",
            color: "#fb0909",
            fontWeight: 400,
            "&:hover": {
              background: "#ffedf0",
            },
          },
        },
        {
          props: { variant: "blue" },
          style: {
            background: "#1F51FF",
            border: "none",
            color: "#FFF",
            fontWeight: 400,
            "&:hover": {
              background: "#1F51FF",
            },
          },
        },
        {
          props: { variant: "draft" },
          style: {
            background: "#CDDEEE",
            border: "none",
            color: "#FFF",
            fontWeight: 400,
            "&:hover": {
              background: "#CDDEEE",
            },
          },
        },
        {
          props: { variant: "gray" },
          style: {
            background: "#CDDEEE",
            border: "none",
            color: "#FFF",
            fontWeight: 400,
            "&:hover": {
              background: "#CDDEEE",
            },
          },
        },
        {
          props: { variant: "white-contained" },
          style: {
            background: "#FFF",
            border: "1px solid #ccc",
            color: "#212121",
            fontWeight: 400,
            "&:hover": {
              background: "#FFF",
            },
          },
        },
      ],
    },
    MuiAutocomplete: {
      styleOverrides: {
        listbox: {
          "& .MuiAutocomplete-option:not(:last-child)": {
            borderBottom: "0.5px solid #ccc",
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        notchedOutline: {
          borderWidth: "0.5px !important",
        },
        borderWidth: "0.5px !important",
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          fontSize: "0.875rem",
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          borderRadius: "unset",
          backgroundColor: "#FFF !important",
        },
      },
    },
    MuiBox: {
      root: {
        textTransform: "inherit",
      },
    },
    MuiTabs: {
      styleOverrides: {
        indicator: {
          background: "linear-gradient(285deg, #032ef7 12%, green 84%)",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
          fontWeight: 500,
        },
      },
      variants: [
        {
          props: { size: "medium" },
          style: {
            fontSize: "0.8rem",
          },
        },
        {
          props: { variant: "eorg" },
          style: {
            borderRadius: 3,
            "&:hover": {
              backgroundColor: "#f3e8f7",
            },
          },
        },
        {
          props: { variant: "plain" },
          style: {
            // border: "none",
            backgroundColor: "#FFF",
            border: "0.5px solid #ccc",
            borderRadius: 3,
            "&:hover": {
              backgroundColor: "#F5F5F7",
              border: "0.5px solid #ccc",
            },
          },
        },
        {
          props: { variant: "input-border" },
          style: {
            border: "1px solid #c6c6c6",
            borderRadius: 3,
            "&:hover": {
              backgroundColor: "#FFF",
              border: "1px solid #636363",
            },
          },
        },
        {
          props: { variant: "bolder" },
          backgroundColor: "transparent",
          style: {
            color: "#000000",
            "&:hover": {
              backgroundColor: "#f3e8f7",
            },
          },
        },
        {
          props: { variant: "lighter" },
          backgroundColor: "transparent",
          color: "#636363",
          style: {
            color: "#636363",
            backgroundColor: "transparent",
            "&:hover": {
              backgroundColor: "#f3e8f7",
            },
          },
        },
        {
          props: { variant: "eorg-plain" },
          style: {
            border: "none",
            backgroundColor: "transparent",
            "&:hover": {
              backgroundColor: "#f3e8f7",
            },
          },
        },
        {
          props: { variant: "eorg-plain-branding" },
          style: {
            border: "none",
            color: "#4B0082",
            backgroundColor: "transparent",
            "&:hover": {
              backgroundColor: "#f3e8f7",
            },
          },
        },
        {
          props: { variant: "eorg", color: "primary" },
          style: {
            backgroundColor: "#4B0082",
            fontWeight: 600,
            color: "#FFFFFF",
            "&:hover": {
              backgroundColor: "#814CA7",
            },
          },
        },
        {
          props: { variant: "success-outlined" },
          backgroundColor: "transparent",
          style: {
            color: "#32CD32",
            border: "1px solid #32CD32",
            "&:hover": {
              backgroundColor: "#32cd322e",
            },
            "&:disabled": {
              border: "1px solid #ccc",
              color: "#ccc",
              cursor: "not-allowed",
            },
          },
        },
        {
          props: { variant: "success-plain" },
          backgroundColor: "transparent",
          style: {
            color: "#32CD32",
            border: "none",
            "&:hover": {
              backgroundColor: "#32cd322e",
            },
            "&:disabled": {
              border: "none",
              color: "#32cd322e",
              cursor: "not-allowed",
            },
          },
        },
        {
          props: { variant: "branding" },
          style: {
            background: "linear-gradient(285deg, #032ef7 12%, #027539 84%)",
            color: "#FFF",
            border: "none",
          },
        },
        {
          props: { variant: "success" },
          backgroundColor: "transparent",
          style: {
            color: "#FFF",
            border: "1px solid #32CD32",
            background: "#32CD32",
            "&:hover": {
              backgroundColor: "#32CD32",
            },
            "&:disabled": {
              border: "1px solid #32CD32",
              color: "#FFF",
              cursor: "not-allowed",
              backgroundColor: "#32CD32",
              opacity: 0.5,
            },
          },
        },
      ],
    },
  },
};

export default LightTheme;
